// https://vuex.vuejs.org/en/getters.html

export default {

  drawer (state) {
    return state.drawer
  },
  titleAppBar (state) {
    return state.titleAppBar
  },
  isAuthenticated: state => !!state.token,
}
