export default {
  empresa: {
    nombre: '',
    descripcion: '',
    notificaciones_correo: '',
    notificaciones_wa: '',
    notificaciones_sms: '',
    logo: '',
    cantidad_dispositivos: '10',
    cantidad_sensores: '1',
    cantidad_alertas: '6',
    cantidad_alertas_mes: '15',
    codigo: '',
  },
  empresaEstadisticas: {
    cantidad_dispositivos: 0,
    cantidad_sensores: 0,
    cantidad_alarmas_dia: 0,
    cantidad_alarmas_mes: 0
  },

  // empresaSensorPrincipal:null
  empresaSensorPrincipal: {
    id: 4,
    nombre: 'Sensor 2',
    codigo: 'S2',
    descripcion: 'asdasdasd',
    codigo_dispositivo: 'D1',
    umbral_medio: '46.00',
    umbral_alto: '405.00',
    tipo_umbral: 'ascendente',
    valor_actual: '12.00',
    registro_promedio: '8.5',
    registro_maximo: '12',
    registro_minimo: '5',
    porcentaje_medio: '0.1086956521739130434782608696',
    porcentaje_alto: '0.01234567901234567901234567901',
    unidad_medida: {
      nombre: 'Kilometros por segundo',
      simbolo: 'Km/s'
    },
    updated: '2020-10-01T02:17:23.233763-05:00',
    principal: true
  },

  usuario: null
}
