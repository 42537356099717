export default {
  SET_DRAWER (state, drawer) {
    state.drawer = drawer
  },
  SET_TITLE_APPBAR (state, titleAppBar) {
    state.titleAppBar = titleAppBar
  },
  SET_TOKEN (state, token) {
    state.token = token
  }
}
