import { set } from '@/utils/vuex'

export default {
  SET_EMPRESA (state, empresa) {
    state.empresa = empresa
  },

  SET_EMPRESA_ESTADISTICAS (state, empresaEstadisticas) {
    state.empresaEstadisticas = empresaEstadisticas
  },

  SET_EMPRESA_SENSOR_PRINCIPAL (state, empresaSensorPrincipal) {
    state.empresaSensorPrincipal = empresaSensorPrincipal
  },

  SET_USUARIO (state, usuario) {
    state.usuario = usuario
  },
}
