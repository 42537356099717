import axios from '../../../plugins/axios'

export default {
  async fetchEmpresa ({ commit }) {
    const empresaResponse = await axios.get('/api/empresas/')
    commit('SET_EMPRESA', empresaResponse.data)
  },

  async fetchEmpresaEstadisticas ({ commit }) {
    const estadisticasEmpresaResponse = await axios.get('/api/empresas/estadisticas/')
    commit('SET_EMPRESA_ESTADISTICAS', estadisticasEmpresaResponse.data)
  },

  async fetchEmpresaSensorPrincipal ({ commit }) {
    const empresaSensorPrincipal = await axios.get('/api/empresas/sensor_principal/')
    commit('SET_EMPRESA_SENSOR_PRINCIPAL', empresaSensorPrincipal.data)
  },

  async fetchUsuario ({ commit }) {
    const usuariosResponse = await axios.get('/api/usuarios/')
    commit('SET_USUARIO', usuariosResponse.data)
  },
}
