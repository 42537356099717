import { set } from '@/utils/vuex'

export default {
  SET_DISPOSITIVOS (state, dispositivos) {
    state.dispositivos = dispositivos
  },

  SET_MARKER_SELECTED(state, markerSelected) {
    state.markerSelected = markerSelected
  }
}
