<template>
  <v-app>
    <router-view/>
    <confirm-modal ref="confirm"/>
  </v-app>
</template>

<script>
import ConfirmModal from '@/components/globals/ConfirmModal'

export default {
  name: 'App',
  components: {
    ConfirmModal
  },
  mounted () {
    this.$root.$confirm = this.$refs.confirm.show
  }
}
</script>

<style>
body {

}
</style>
