// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'

export default {
  setDrawer({ commit }, drawer) {
    commit('SET_DRAWER', drawer)
  },
  setTitleAppBar({ commit }, titleAppBar) {
    commit('SET_TITLE_APPBAR', titleAppBar)
  },
  async login({ commit }, payload) {
    try {
      const clientId = process.env.VUE_APP_CLIENT_ID
      const clientSecret = process.env.VUE_APP_CLIENT_SECRET
      const grant_type = 'password'
      const form = `client_id=${clientId}&client_secret=${clientSecret}&grant_type=${grant_type}&username=${payload.username}&password=${payload.password}`
      const tokenResponse = await axios.post('api/oauth/token', form, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      commit('SET_TOKEN', tokenResponse.data)
      localStorage.setItem('token', JSON.stringify(tokenResponse.data))
    } catch (error) {
      localStorage.clear('token')
      if (error.response.status === 401 && error.response.data.message) {
        throw new Error(error.response.data.message)
      }
      throw new Error('No se puede iniciar sesión, intente nuevamente en unos minutos.')
    }
  },
  logout: ({ commit }) => {
    commit('SET_TOKEN', null)
    localStorage.clear('token')
  },
}
