import axios from '../../../plugins/axios'

export default {
  async fetchDispositivos ({ commit }) {
    const dispositivosResponse = await axios.get('/api/iot/dispositivos/')
    commit('SET_DISPOSITIVOS', dispositivosResponse.data)
  },

  markerSelected ({ commit }, markerSelected){
    commit('SET_MARKER_SELECTED', markerSelected)
  }
}
