<template>
  <v-dialog
    v-model="showModal"
    class="modal"
    max-width="640px"
    min-height="520px"
  >
    <v-card class="fill-height pa-5">
      <div style="margin: auto;">

        <v-container class="d-flex flex-column justify-center align-center pt-5 px-4">
          <v-img max-width="122px" max-height="122px" src="/img/icons/check-icon.svg" class="my-8"></v-img>
          <p v-if="title" class="modal__title mb-5">
            {{ title }}
          </p>
          <p v-if="message" class="modal__message mb-5">
            {{ message }}
          </p>
        </v-container>

        <v-spacer/>

        <v-card-actions>
          <v-btn v-if="confirmButtonText" @click="confirm()" elevation="0" rounded height="64px" block
                 color="#363740"
                 class="login-button" dark>
            {{ confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: ['loading'],
  data () {
    return {
      showModal: false,
      resolve: null,
      title: '',
      message: '',
      type: 'check',
      confirmButtonText: '',
      showDismissButton: true
    }
  },
  methods: {

    show ({ title, message, type, confirmButtonText, showDismissButton }) {
      this.message = message
      this.title = title
      this.type = type || 'check'
      this.confirmButtonText = confirmButtonText
      this.showDismissButton = showDismissButton === undefined ? true : showDismissButton
      this.showModal = true

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    cancel () {
      this.resolve(false)
      this.showModal = false
      this.restoreDefaultValues()
    },
    confirm () {
      this.resolve(true)
      this.showModal = false
      this.restoreDefaultValues()
    },
    restoreDefaultValues () {
      this.message = ''
      this.type = 'alert'
      this.confirmButtonText = ''
      this.showDismissButton = true
    }
  }
}
</script>

<style scoped lang="scss">
.modal {


  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
  }

  &__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

::v-deep .v-dialog {
  border: 1px solid rgba(102, 102, 102, 0.5);
  border-radius: 24px;
}
</style>
